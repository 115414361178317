<template>
	<v-container class="mt-4 ml-2" fluid>
	  <v-row>
	  	<v-col cols="12">
	  		<v-card class="shadowCard">
	  		  <v-card-title primary-title>
	  		    <span class="text-subtitle-1">Solicitudes de compra</span>
	  		    <v-spacer></v-spacer>
	  		    <v-btn 
              class="mr-2" 
              small dark color="green"
              @click="exportar()">
              <v-icon>mdi-microsoft-excel</v-icon>
            </v-btn>
	  		  </v-card-title>
	  		  <v-card-text>
	  		  	<v-row justify="end">
	  		  		<v-col cols="12" md="4" lg="3">
	  		  			<v-text-field
	  		  			  name="name"
	  		  			  label="Buscar"
	  		  			  id="id"
	  		  			  filled
	  		  			  dense
	  		  			  hide-details
	  		  			  append-icon="mdi-magnify"
	  		  			  v-model="search"
	  		  			></v-text-field>
	  		  		</v-col>
	  		  	</v-row>
	  		    <v-row>
	  		    	<v-col cols="12">
							  <v-data-table
							  	v-model="imprimirSolicitudes"
							    :headers="headers"
							    :items="solicitudes"
							    class="elevation-0"
							    :search="search"
							    :mobile-breakpoint="100"
			            dense
							    item-key="idsolicitudes"
							    show-select
							  >
							    <!-- Valores que ocupan botons, chips, iconos, etc -->
							    <template v-slot:item.estatus="{ item }">
							      <v-chip 
							      	v-if="item.estatus == 1"
							      	small
							      	color="orange"
							      	dark
							      >Incompleta</v-chip>

							      <v-chip 
							      	v-else-if="item.estatus == 0"
							      	small
							      	color="error"
							      	dark
							      >Pendiente</v-chip>

							      <v-chip 
							      	v-else
							      	small
							      	color="success"
							      	dark
							      >Completa</v-chip>
							    </template>

							    <template v-slot:item.data-table-select="{ isSelected, select, item }">
									  <v-checkbox 
									  	v-if="item.estatus == 1"
									  	:input-value="isSelected"
									  	@change="select($event)"
									  	hide-details
									  	dense
									  ></v-checkbox>
									</template>

							    <template v-slot:item.actions="{ item }">
							    	<v-btn 
							    		color="primary" 
							    		x-small
							    		@click="editItem(item)"
							    		class="mr-2"
							    	>
								      <v-icon small>mdi-magnify</v-icon>
							    	</v-btn>
						    	
							    	<v-btn 
							    		color="error" 
							    		x-small
							    		@click="deleteItem(item)"
							    		class="mr-2"
							    	>
								      <v-icon small> mdi-delete</v-icon>
							    	</v-btn>
							    </template>

							    <template v-slot:no-data>
							      <v-btn
							        color="primary"
							        @click="initialize"
							        small
							      >
							        Actualizar
							      </v-btn>
							    </template>

							  </v-data-table>
	  		    	</v-col>
	  		    </v-row>
	  		  </v-card-text>
	  		</v-card>
	  	</v-col>
	  </v-row>

	  <!-- Dialogo de editar o agregar-->
	  <v-dialog
      v-model="dialog"
      max-width="800px"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="text-subtitle-1">{{ formTitle }}</span><br/>
          <v-spacer></v-spacer>
          <span class="text-subtitle-1">
          	<strong>ID: {{ editedItem.idsolicitudes }}</strong>
          </span>
        </v-card-title>

        <v-card-text>
          <span class="text-body-1 primary--text pb-1">{{ editedItem.plantel }}</span>
          <v-row>
            <v-col cols="12">
              <v-data-table
						    :headers="headersCompra"
						    :items="editedItem.articulos"
						    class="elevation-0"
						    :search="search"
		            dense
		            :items-per-page="5"
						  >

						  	<template v-slot:item.foto="{ item }">
				          <v-img 
				            :src="url + item.foto" 
				            height="60px"
				            width="60px"
				            contain 
				            v-if="item.foto"
				            class="white"
				          >
				          </v-img>
						    </template>

						    <template v-slot:item.estatus="{ item }">
						      <v-chip 
						      	v-if="item.estatus == 1"
						      	small
						      	color="green"
						      	dark
						      >Completa</v-chip>

						      <v-chip 
						      	v-else
						      	small
						      	color="error"
						      	dark
						      >Pendiente</v-chip>
						    </template>

						    <template v-slot:no-data>
						      <v-btn
						        color="primary"
						        @click="initialize"
						        small
						      >
						        Actualizar
						      </v-btn>
						    </template>

						  </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="close"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="blue darken-1"
            class="elevation-6"
            dark
            @click="save"
          >
            Aceptar solicitud
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialogo para eliminar -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
      	<v-card-text>
	      	<v-row justify="space-around">
	      		<v-col cols="6">
	      			<v-card class="elevation-0" height="100%" style="display: table;">
		      			<div class="text-subtitle-1" style="display: table-cell; vertical-align: middle;">
		      				<strong>Eliminar</strong> ¿Estás seguro que deseas eliminar la solicitud?
			      			<v-card-actions class="pt-6">
			      				<!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
			      			  <v-btn color="error" text  class="mr-2" @click="closeDelete()">No, cancelar</v-btn>
			      			  <!-- Guardar la información  -->
			      			  <v-btn color="#5C5C5C"  dark class="elevation-6"  @click="deleteItemConfirm()">Si</v-btn>
			      			</v-card-actions>
		      			</div>
	      			</v-card>
	      		</v-col>
	      		<v-col cols="5">
	      			<v-img src="@/assets/borrar.png"></v-img>
	      		</v-col>
		      </v-row>
      	</v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-container>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import funcionesExcel  from '@/mixins/funcionesExcel'
  import validarErrores  from '@/mixins/validarErrores'

  export default {
  	components:{
      Alerta,
      carga,
    },

    mixins: [ funcionesExcel, validarErrores ],

    data: () => ({
    	url   : '' ,
    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      dialog: false,
      dialogDelete: false,
      
      editedIndex: -1,

      editedItem: {
        idsolicitudes:0,
        articulos:[],
				forma_pago:'',
				comentarios:'',
				fecha_alta:null,
				activo_sn:0,
				fecha_baja:null,
				id_usuario_ultimo_cambio:0,
				fecha_ultimo_cambio:null,
      },

      defaultItem: {
        idsolicitudes:0,
				forma_pago:'',
				comentarios:'',
				fecha_alta:null,
				activo_sn:0,
				fecha_baja:null,
				id_usuario_ultimo_cambio:0,
				fecha_ultimo_cambio:null,
      },

      // tabla
			search:'',
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      solicitudes: [],
      headers: [
        { text: 'ID'               , value: 'idsolicitudes'    },
        { text: 'Plantel'          , value: 'plantel'          },
        { text: 'Usuario'          , value: 'empleado'         },
        { text: 'Estatus'          , value: 'estatus'          },
        { text: 'Alta'             , value: 'fecha_creacion'   },
        { text: 'Actions'          , value: 'actions', sortable: false },
      ],

      headersCompra: [
        { text: 'id'               , value: 'iddesgloce_solicitud'    },
        { text: 'articulo'         , value: 'nomart'                  },
        { text: 'foto'             , value: 'foto'                    },
        { text: 'cantidad'         , value: 'cantidad'                },
        { text: 'Recibí'           , value: 'cantidad_recibida'       },
        { text: 'Estatus'          , value: 'estatus'                 },
      ],

      imprimirSolicitudes:[]
    }),

    computed: {
    	...mapGetters('login',['getdatosUsuario']),
      formTitle () {
        return this.editedIndex === -1 ? 'Agregar Forma de pago' : 'Ver Pedido'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },

    },

    async created () {
    	this.url = axios.defaults.baseURL + 'imagenes-tienda/'
      await this.initialize()
    },

    methods: {
      initialize () {
      	this.cargar = true
        this.solicitudes = []
        return this.$http.get('articulos.solicitudes').then(response=>{
        	this.solicitudes = response.data
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      enterSelect ( ){
      	const ultimaPosicion = this.imprimirSolicitudes.length - 1
      	const { estatus } = this.imprimirSolicitudes[ultimaPosicion]
      	if( estatus == 0 ){
      		// eliminiar la ultimaPosicion
      		this.imprimirSolicitudes.unshift()
      	}
      },

      editItem (item) {
        this.editedIndex = this.solicitudes.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.solicitudes.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
      	this.cargar = true
        // Lo mandapos por el EP
        this.$http.put('articulos.solicitudes.eliminar/' + this.editedItem.idsolicitudes).then(response=>{
        	this.cargar = true
        	this.closeDelete()
        	this.initialize()
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
	      this.cargar = true
        // Lo mandapos por el EP
        this.$http.put('articulos.solicitudes.update/' + this.editedItem.idsolicitudes ).then(response=>{
        	this.cargar = false
        	this.validarSuccess( response.data.message )
      		this.close()
        	this.initialize()
        }).catch( error =>{
        	this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

    	exportar( ){

    		if( !this.imprimirSolicitudes.length ){
    			return this.validarErrorDirecto( 'Por favor, seleccionar primero las solicitudes a imprimir :p' )
    		}

    		let productos = []

    		for( const i in this.imprimirSolicitudes ){
    			const { articulos, estatus } = this.imprimirSolicitudes[i]
    			if( estatus == 1 ){
    				productos = productos.concat( articulos )
    			}
    		}

    		let arrayArticulos = productos.map(item=>{
          return [item.idarticulo,item]
        });

        // Creamos un map de los alumnos
        let mapArticulos = new Map(arrayArticulos); // Pares de clave y valor
        // y ahora si, extraemos los valores unicos
        let articuloUnico = [...mapArticulos.values()]; // Conversión a un array

        let pedido = []

        for( const i in articuloUnico){
        	let { idarticulo, nomart } = articuloUnico[i]

        	pedido.push({
        		idarticulo,
        		articulo: nomart,
        		cantidad: productos.filter( el => { return el.idarticulo == idarticulo }).map(item => item.cantidad).reduce((prev, curr) => prev + curr, 0)
        	})
        }

        this.exportExcel( pedido, 'pedido_articulos')
    	}
    },
  }
</script>


